import "./MainLayout.scss";
import { MainSidebar } from "../MainSidebar/MainSidebar";
import { Outlet } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useAxiosLoader } from "../../services/Axios/AxiosInterceptor";
import Header from "../Header/Header";
import { BreadCrumbProvider } from "../../services/Context/Context";
import { Breadcrumbs } from "../BredCrumbs/BredCrumbs";

const MainLayout = () => {
  const loading: any = useAxiosLoader();

  return (
    <div className="main-layout">
      <BreadCrumbProvider>
        {/* <div className="main-header">
        <Header />
      </div> */}
        <div className="main-body">
          <div className="main-sidebar">
            <MainSidebar />
          </div>
          <div className="main-right-side">
            <div className="main-header">
              <Header />
              <Breadcrumbs />
            </div>
            <div className="main-content">
              <Outlet />
            </div>
          </div>
        </div>

        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </BreadCrumbProvider>
    </div>
  );
};

export default MainLayout;
